import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import routesConfig from "./utils/routesConfig";
import AppExtras from "./components/AppExtras/AppExtras";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {routesConfig.map(({ path, component: Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <AppExtras />
    </>
  );
}

export default App;
