import React, { useState } from "react";
import styles from "./Wishlists.module.scss";
import { useWishlistsHook } from "../../hooks/useWishlistsHook";
import List from "./List";

const Wishlists = () => {
  const {
    isLoading,
    data,
    createNewWishlist,
    getListProps,
    handleBack,
    handleWishlistClick,
    selectedWishlist,
  } = useWishlistsHook();

  if (selectedWishlist) {
    return (
      <div className={styles.container}>
        <div className={styles.backButtonWrapper}>
          <button className={styles.backButton} onClick={handleBack}>
            ← Назад
          </button>
        </div>
        <div className={styles.selectedWishlist}>
          <div className={styles.wishlistHeader}>
            <h2>
              {selectedWishlist.user_name || "Анон"}
              {selectedWishlist.private && (
                <span className={styles.privateIcon}>🔒</span>
              )}
            </h2>
            {selectedWishlist.name && (
              <h3 className={styles.wishlistTitle}>{selectedWishlist.name}</h3>
            )}
          </div>
          <List {...getListProps(selectedWishlist)} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonGroup}>
        <button
          className={styles.createList}
          onClick={createNewWishlist(false)}
          disabled={isLoading}
        >
          Создать вишлист
        </button>
        <button
          className={styles.createPrivateList}
          onClick={createNewWishlist(true)}
          disabled={isLoading}
        >
          Создать приватный вишлист
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles.grid}>
          {data.map((user) => (
            <div
              key={user.id}
              className={styles.card}
              onClick={() => handleWishlistClick(user)}
            >
              <span className={styles.userName}>{user.user_name}</span>
              {user.name && (
                <span className={styles.wishlistName}>{user.name}</span>
              )}
              <div className={styles.itemCount}>
                {user.items.data.length}{" "}
                {user.items.data.length === 1 ? "wish" : "wishes"}
              </div>
              {user.private && <span className={styles.privateIcon}>🔒</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wishlists;
