import React from "react";
import { NotificationContainer } from "react-notifications";

const AppExtras = () => {
  return (
    <>
      <NotificationContainer />
    </>
  );
};

export default AppExtras;
