import React from "react";
import DataRenderer from "../../components/DataRenderer/DataRenderer";
import { useWishHook } from "../../hooks/useWishHook";
import paths from "../paths";
import styles from "./Wish.module.scss";
import WishContent from "./WishContent";

const Wish = () => {
  const { id, navigate, data, getWishContentProps } = useWishHook();

  if (!id) {
    navigate(paths.wishlists);
  }

  return (
    <div className={styles.container}>
      {data && (
        <div className={styles.wishContainer}>
          <div className={styles.header}>
            <h2>{data?.user_name || "Анон"}</h2>
            {data?.name && <h3>{data?.name}</h3>}
          </div>
          <div className={styles.wishes}>
            <DataRenderer
              data={data?.items.data}
              Component={WishContent}
              getProps={getWishContentProps}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Wish;
